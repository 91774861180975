<template>
  <div>
    <navbarLanding-component :texts="texts" />
    <div class="bg-container">
      <HomeBannerLandingLayout :texts="texts" id="Home"/>
      <HomeAboutLayout :texts="texts" style="padding-bottom: 80px; display: flex;" id="About"/>
      <HomeBuyerLayout :selectLanguage="selectLanguage" :texts="texts" id="Buyers" style="margin-top: -7px;"/>
      <!-- Se agregaron las siguientes líneas de código para mostrarse en el landing según el diseño en Figma -->
      
      <!-- Se comentó la siguiente línea de código para no mostrarse en el landing según el diseño en Figma -->
      <!-- <HomeProviderLayout :selectLanguage="selectLanguage" :texts="texts" id="Providers"/> -->
    </div>
    <footerLanding-component :texts="texts"/>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {},
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 0px;
}
</style>