<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row class="ma-0 mb-5">
        <div v-if="!isLoading" class="ma-4">
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">Forwards Activos</div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">
              Aqui encontraras tus forwards activos de que estan pendientes de realizarse.
            </div>
          </v-col>
        </div>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          height="200px"
          width="400px"
          type="image"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
        <v-card v-if="!isLoading" class="card-main mx-3 my-3" style="border: 1px solid #C5CDD3; height: fit-content;" width="350px" elevation="0" outlined>
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">USD Balance</div>
                <div class="content-balance">
                  ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  src="../../assets/images/Flags_usa.png"
                  alt="usa-flag"
                  width="50"
                  height="30"
                />
              </v-col>
            </v-row>
            <div class="pl-3 pt-2 blocked-amount">
              Monto bloqueado forwards:
              <span class="blocked-amount-2">$0.00</span>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="300px"
          type="image"
        ></v-skeleton-loader>
      </v-row>
      <v-card v-if="!isLoading" class="rounded-xl elevation-5">
        <v-toolbar dense dark color="#142e53" elevation="2">
          <v-toolbar-title>Forwards Activos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="dataXLSX()">
            Descargar
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-card-title class="mb-4" style="display: flex; justify-content: flex-end; font-family: 'montserrat-bold';">
          <div style="background: #FFFF; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 15px; border: 1px solid #C5CDD3;">
            Total currency gain:
            <span class="ml-2" style="font-weight: bold;" :style="{ color: items.totalProfit < 0 ? 'red' : 'green'}">
              {{ " $" + formatMoney(items.totalProfit) + " USD" }}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersForwards" :items="conversions">
            <template v-slot:item.type="{ item }">
              <v-chip
                v-if="item.diasFaltantes > 0"
                class="ma-2"
                color="blue"
                outlined
              >
                {{ "Forward" }}
              </v-chip>
              <v-chip
                v-else
                class="ma-2"
                color="red"
                outlined
              >
                {{ "Forward" }}
              </v-chip>
            </template>
            <template v-slot:item.iTrackingId="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ item.iTrackingId }}
              </h4>
            </template>
            <template v-slot:item.diasFaltantes="{ item }">
              <h4 :style="{ color: item.diasFaltantes < 0 ? 'red' : 'black'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.diasFaltantes + " days" }} </h4>
            </template>
            <template v-slot:item.invoice="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoice ? item.invoice : "N/A" }}</h4>
            </template>
            <template v-slot:item.purchaseDate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.purchaseDate ? item.purchaseDate : "N/A" }}  </h4>
            </template>
            <template v-slot:item.percentProfit="{ item }">
              <h4 v-if="item.percentProfit" :style="{ color: item.percentProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.percentProfit ? (formatMoney(item.percentProfit) + "%") : "N/A" }} </h4>
              <h4 v-else> {{ "N/A" }}</h4>
            </template>
            <template v-slot:item.dollarProfit="{ item }">
              <h4 v-if="item.dollarProfit" :style="{ color: item.dollarProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dollarProfit ? ('$ ' + formatMoney(item.dollarProfit) + ' USD') : "" }}</h4>
              <h4 v-else> {{ "N/A" }}</h4>
            </template>
            <template v-slot:item.dAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dAmount) }} USD </h4>
            </template>
            <template v-slot:item.dDesAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dDesAmount) }} MXN </h4>
            </template>
            <template v-slot:item.dExchangeRate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dExchangeRate) }} MXN </h4>
            </template>
            <template v-slot:item.created_at="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ formatDate(item.created_at) }} </h4>
            </template>
            <template v-slot:item.dForwardTransactionDate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ formatDate(item.dForwardTransactionDate) }} </h4>
            </template>
            <template v-slot:item.dProfitPercentage="{ item }">
              <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dProfitPercentage ? (item.dProfitPercentage + "%") : "" }} </h4>
            </template>
            <template v-slot:item.dCurrencyGain="{ item }">
              <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dCurrencyGain ? ('$ ' + formatMoney(item.dCurrencyGain) + ' USD') : "" }}  </h4>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="display: flex; align-items: center; justify-content: center;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openDialog(item)" v-bind="attrs" v-on="on" icon color="black">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar forward</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openPayment(item)" :disabled="item.diasFaltantes > 0" v-bind="attrs" v-on="on" icon color="black">
                      <v-icon>mdi-cash-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Solicitar pago</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="500px"
        type="image"
      ></v-skeleton-loader>
    </v-container>
    <v-dialog v-model="dialogDetailConversion" max-width="1000">
      <v-card>
        <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
          <h3 class="font-weight-bold my-4">
            Detalle de {{ "conversión" }}
          </h3>
        </v-card-title>
        <v-card-text class="pb-5 px-10">
          <div class="my-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                Tipo
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ conversion.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a' ? "Forex" : "Forward" }}
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px; text-align: center;">
                  Monto
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ "$ " + formatMoney(conversion.dDesAmount) + " MXN" }}
                </div>
              </div>
              <template v-if="conversion.invoice">
                <div v-if="conversion.invoice.length > 0">
                  <div style="color: black; font-size: 20px;">
                    Profit
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.percentProfit) + "%"  }}
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.dollarProfit) + " USD"  }}
                  </div>
                </div>
                <div v-else></div>
              </template>
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;" v-if="conversion.invoice">
                Supplier: <span style="color: black; font-size: 22px; font-weight: bold;" v-if="conversion.invoice.length > 0"> {{ conversion.invoice[0].invoice.paymentsRecipent[0].sName }}</span>
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px;">
                Exchange rate: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ "$ " + conversion.dExchangeRate + " MXN" }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                Fecha de creación: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.created_at) }}</span>
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px;" v-if="conversion.dForwardTransactionDate">
                  Fecha de cumplimiento: <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.dForwardTransactionDate) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold;">Porcentaje de la conversión utilizado</div>
            <v-progress-linear
              height="45"
              :value="conversion.percent"
            >
              <strong style="color: white;">{{ formatMoney(conversion.percent) }}%</strong>
            </v-progress-linear>
          </div>
          <div>
            <div class="mb-6" style="display: flex; justify-content: space-around; color: black; font-size: 22px; font-weight: bold;">
              <div>Facturas asignadas</div>
              <v-spacer></v-spacer>
              <v-autocomplete
                label="Nombre del proveedor*"
                required
                :items="suppliers"
                :disabled="conversion.invoice.length > 0"
                class="pt-0 mt-0"
                v-if="active"
                item-value="sPaymentRecipientId"
                item-text="sName"
                @change="getInvoicesWithSupplier(sPaymentRecipientId)"
                v-model="sPaymentRecipientId"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-btn
                style="
                  text-align: center;
                  color: #0075B8;
                  font-size: 15px;
                  background-color: transparent;
                  font-family: montserrat-bold;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  word-wrap: break-word;
                  border-radius: 5px;
                  border: 1px solid #0075B8;
                  padding: 8px 16px;
                "
                elevation="0"
                @click="updateInvoice()"
                class="ml-2"
                :disabled="conversion.percent == 100 || loadingTransaction"
              >
                <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
                Agregar facturas
              </v-btn>
            </div>
            <div v-if="conversion.invoice" style="display: flex; justify-content: flex-start; gap: 30px">
              <v-card :disabled="active" class="mb-4 mx-4" style="width: 340px;" v-for="(item, idx) in conversion.invoice" :key="idx">
                <v-card-title>
                  <h3>Invoice: {{ item.invoice.sInvoice }}</h3>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="openInvoice(item)" :disabled="false" v-bind="attrs" v-on="on" icon color="black">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar factura</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="deleteInvoice(item)" v-bind="attrs" v-on="on" icon color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar factura de la conversión</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div class="mt-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
                    <div style="display: flex; justify-content: space-between; align-items: center;  align-items: flex-start; flex-direction: column;">
                      <div class="my-2">
                        <div style="color: black; font-size: 20px;">
                          Monto
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.invoice.dTotalAmount) + "MXN" }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px;">
                        Fecha de compra
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ formatDate(item.invoice.dPurchaseDate) }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px; text-align: center;">
                          Exchange rate protected
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.invoice.dRateProtection) + " MXN" }}
                        </div>
                      </div>
                      <div class="my-2" style="display: flex; width: 100%; justify-content: space-around;">
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                            Profit (%)
                          </div>
                          <div :style="{ color: item.invoice.dProfitPercentage < 0 ? 'red' : 'green'}" style="color: black; font-size: 22px; font-weight: bold;">
                            {{ item.invoice.dProfitPercentage + "%" }}
                          </div>
                        </div>
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                          Profit
                          </div>
                          <div :style="{ color: item.invoice.dProfitPercentage < 0 ? 'red' : 'green'}"style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + item.invoice.dCurrencyGain + " USD" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-if="!isLoadingInvoices" class="mb-4" v-for="(item,idx) in avaibleInvoices" :key="idx" style="background-color: #f3f3f369;">
                <v-card-title>
                  <div style="display: flex; flex-direction: column; width: 200px">
                    <h3>Invoice: {{ item.sInvoice }}</h3>
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="text-align: center; color: #0075B8; font-size: 15px; background-color: transparent; font-family: montserrat-bold; font-weight: 600; line-height: 24px; text-transform: capitalize; word-wrap: break-word; border-radius: 5px; border: 1px solid #0075B8; padding: 8px 16px;"
                        elevation="1"
                        @click="addInvoice(item)"
                        class="ml-2"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        Agregar
                      </v-btn>
                    </template>
                    <span>Agregar factura a la invoice</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div class="mt-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
                    <div style="display: flex; justify-content: space-between; align-items: center;  align-items: flex-start; flex-direction: column;">
                      <div class="my-2">
                        <div style="color: black; font-size: 20px;">
                          Monto
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.dTotalAmount) + "MXN" }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px;">
                        Fecha de compra
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ formatDate(item.dPurchaseDate) }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px; text-align: center;">
                          Exchange rate protected
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">
                          {{ "$ " + formatMoney(item.dRateProtection) + " MXN" }}
                        </div>
                      </div>
                      <div class="my-2" style="display: flex; width: 100%; justify-content: space-around;">
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                            Profit (%)
                          </div>
                          <div :style="{ color: item.dProfitPercentage < 0 ? 'red' : 'green'}" style="color: black; font-size: 22px; font-weight: bold;">
                            {{ formatMoney(item.dProfitPercentage) + "%" }}
                          </div>
                        </div>
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px;">
                          Profit
                          </div>
                          <div :style="{ color: item.dCurrencyGain < 0 ? 'red' : 'green'}"style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.dCurrencyGain) + " USD" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
              elevation="0"
              class="button-cancel mon-regular"
              @click="cancel()"
            >
              {{ "Cerrar" }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center;">
          <span class="text-h5">Editar factura</span>
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px;" >Ingresa la siguiente información para agregar una nueva factura.</span>
        </v-card-subtitle>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form ref="formEdit" style="width: 100%;" lazy-validation v-on:submit.prevent>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre de la invoice*"
                    required
                    v-model="invoice.sInvoice"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Nombre del proveedor*"
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="invoice.sPaymentRecipientId"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Fecha de compra*"
                    required
                    type="date"
                    v-model="invoice.dPurchaseDate"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Total en pesos MXN*"
                    required
                    type="number"
                    v-model="invoice.dTotalAmount"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tiempo del credito*"
                    required
                    type="number"
                    v-model="invoice.iCreditPeriod"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tipo de cambio protegido*"
                    required
                    type="number"
                    v-model="invoice.dRateProtection"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="dialogEditInvoice = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#0075B8"
            :disabled="loading"
            @click="saveInvoice()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRequestPayment" persistent max-width="fit-content">
      <v-card>
        <v-card-title class="text-h5">
          ¿Estás seguro de que deseas solicitar el pago total del forward?
        </v-card-title>
        <v-card-text v-if="!loading" class="mt-6">Una vez que realizas la transacción se revisara que tu saldo USD sea suficiente para pagar el total del forward.</v-card-text>
        <v-card-text v-if="!loading" class="mb-6">El monto se ira a la pestaña de saldo disponible MXN.</v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="cancel()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#0075B8"
            class="white--text"
            :disabled="loading"
            @click="closeForward()"
          >
            Pagar forward
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      nameXLSX: "ForwardActivos.xlsx",
      texts: "",
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      selected: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      tab: 0,
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      conversion: {},
      active: false,
      avaibleInvoices: [],
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      suppliers: [],
      loading: false,
      dialogEditInvoice: false,
      invoice: {},
      dialogRequestPayment: false,
      dialogDetailConversion: false,
      loadingTransaction: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      today: null,
      e1: 1,
      timerCount: 30,
      conversions: [],
      headersForwards: [
        { text: "Id", value: "iTrackingId", align: "center", sortable: false },
        { text: "Transaction date", value: "created_at", align: "center" },
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Due date", value: "dForwardTransactionDate", align: "center" },
        { text: "Missing days", value: "diasFaltantes", align: "center" },
        { text: "Total amount USD", value: "dAmount", align: "center" },
        { text: "Total amount MXN", value: "dDesAmount", align: "center" },
        { text: "Exchange rate", value: "dExchangeRate", align: "center" },
        { text: "Invoice", value: "invoice", align: "center", sortable: false },
        { text: "Purchase date", value: "purchaseDate", align: "center", sortable: false },
        { text: "Currency profit (%)", value: "percentProfit", align: "center", sortable: false },
        { text: "Currency gain", value: "dollarProfit", align: "center", sortable: false },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" }
      ],
      transactions: [],
      invoices: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    cancel() {
      this.dialogEditInvoice = false;
      this.dialogDetailConversion = false;
      this.dialogRequestPayment = false;
    },
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#0075B8" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold"
      };
    },
    getForwards() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/getForwardsActive`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.items = response.data;
        this.conversions = this.items.forwards;
        this.isLoading = false;

        this.balanceUsd = this.items.balance.balanceUsd.balance;
        this.conversions = this.conversions.map((item) => {
          let invoices = []
          let purchaseDate = []
          if (item.invoice.length > 0) {
            item.invoice.forEach(invoice => {
              invoices.push(invoice.invoice.sInvoice)
              purchaseDate.push(this.formatDate(invoice.invoice.dPurchaseDate))
            })
          }
          item.invoice = invoices.join(', ');
          item.purchaseDate = purchaseDate.join(', ');
          // Fecha actual en formato YYYY-MM-DD
          const today = moment().format('YYYY-MM-DD');

          // Crear objetos moment a partir de las fechas
          const momentToday = moment(today, 'YYYY-MM-DD');
          const momentOtherDate = moment(item.dForwardTransactionDate, 'YYYY-MM-DD');

          // Calcular la diferencia en días
          const diferencia = momentOtherDate.diff(momentToday, 'days');

          item.diasFaltantes = diferencia
          return item;
        });
        this.getSuppliers();
      }).catch((error) => {
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          // this.isLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    openPayment(item) {
      this.forward = item;
      this.dialogRequestPayment = true;
    },
    closeForward() {
      this.loading = true;

      const payload = {
        sTransactionId: this.forward.sTransactionId,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/closeForward`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.cancel();
          this.getForwards();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openDialog(item) {
      this.dialogDetailConversion = true;
      this.getConversionsWithId(item)
    },
    getConversionsWithId(item) {
      this.loadingTransaction = true;
      this.conversion = [];

      const payload = {
        sTransactionId: item.sTransactionId,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/id`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.conversionWithId = response.data.transactions.map(transaction => {
            let totalAmount = 0;
            if (transaction.invoice.length > 0) {
              transaction.invoice.map(invoice => {
                totalAmount += parseFloat(invoice.invoice.dTotalAmount);
              });
            }
            transaction.totalAmount = totalAmount;
            transaction.percent = (transaction.totalAmount / transaction.dDesAmount) * 100
            return transaction;
          });

          this.conversion = this.conversionWithId[0];

          if (this.conversion.invoice.length > 0) {
            this.sPaymentRecipientId = this.conversion.invoice[0].invoice.paymentsRecipent[0].sPaymentRecipientId
          } else {
            this.sPaymentRecipientId = null;
          }

          this.getForwards();
          this.loadingTransaction = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    updateInvoice() {
      this.active = true;
      if (this.sPaymentRecipientId) {
        this.getInvoicesWithSupplier(this.sPaymentRecipientId)
      }
    },
    getInvoicesWithSupplier(item){
      this.isLoadingInvoices = true;
      this.avaibleInvoices = [];

      const payload = {
        sPaymentRecipientId: item,
        dExchangeRate: this.conversion.dExchangeRate
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/invoices/supplier`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.avaibleInvoices = response.data.invoices
          this.isLoadingInvoices = false;
        })
        .catch((error) => {
          this.isLoadingInvoices = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getSuppliers() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.isLoadingSuppliers = false;
        this.suppliers = response.data.paymentRecipients
      })
      .catch((error) => {
        this.isLoadingSuppliers = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    addInvoice(item) {
      this.loadingTransaction = true;
      this.avaibleInvoices = [];

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount
          }
        ]
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/invoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.loadingTransaction = false;
        this.getConversionsWithId(this.conversion);
        this.active = false;
      })
      .catch((error) => {
        this.loadingTransaction = false;
        this.getInvoicesWithSupplier(this.sPaymentRecipientId)
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    openInvoice(item) {
      this.loading = true;
      const payload = {
        sInvoiceId: item.sInvoiceId
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/invoices/id`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
      .then((response) => {
        this.loading = false;
        this.invoice = response.data.invoice
        this.invoice.dPurchaseDate = moment(response.data.invoice.dPurchaseDate).format('YYYY-MM-DD')
        this.dialogEditInvoice = true;
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    saveInvoice() {
      if (this.$refs.formEdit.validate()) {
        this.loading = true;

        let payload = {
            sInvoiceId: this.invoice.sInvoiceId,
            dPurchaseDate: this.invoice.dPurchaseDate,
            sPaymentRecipientId: this.invoice.sPaymentRecipientId,
            sInvoice: this.invoice.sInvoice,
            dTotalAmount: this.invoice.dTotalAmount,
            dRateProtection: this.invoice.dRateProtection,
            iCreditPeriod: this.invoice.iCreditPeriod,
            sTransactionId: this.conversion.sTransactionId,
            exchangeRate: this.conversion.dExchangeRate
        };

        DB.put(
            `${URI}/api/v1/${this.selectLanguage}/invoices/conversion`,
            payload,
            {
              headers: {
                Authorization: this.$store.state.sToken
                  ? `Bearer ${this.$store.state.sToken}`
                  : ""
              }
            }
          )
        .then((response) => {
          this.loading = false;
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);
          this.active = false;
          this.dialogEditInvoice = false;
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    deleteInvoice(item) {
      this.loadingTransaction = true;

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount
          }
        ]
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/deleteInvoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);

          this.active = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getActualCurrency: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rate-global`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        if (!response.data.status) {
          this.mixError(response.data.message);
          return;
        }
        this.referenceCurrency = response.data.reference;
        this.actualCurrency = response.data.mxnRate;
        this.actualCurrencyMxn = response.data.usdRate.toString();
        this.getExchangeRate();
        this.getInvoices();
        this.getTransactions();
      });
    },
    getInvoices() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/?exchangeRateToday=${this.actualCurrency}`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    getTransactions() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.transactions = response.data.transactions
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    getExchangeRate: function () {
      this.items = [];

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken ? `Bearer ${this.$store.state.sToken}` : ""
          }
        }
      )
        .then((response) => {
          this.isForwardsActive = response.data.isForwardsActive;
          this.userName = response.data.user;
          this.isRegisterEfex = response.data.registerInEfex;
          this.exchangeRateDataPaid =
            response.data.exchangeRate.exchangeRatesStatePaid;
          this.exchangeRateData =
            response.data.exchangeRate.exchangeRatesStateToPay;
          this.exchangeRateDataSeparate =
            response.data.exchangeRate.exchangeRatesStateSeparated;
          this.exchangeRatesStateForwards =
            response.data.exchangeRate.exchangeRatesStateForwards;
          this.balanceMxn = response.data.balance.balanceMxn;
          this.balanceUsd = response.data.balance.balanceUsd;
          this.exchangeRateAdjustments = response.data.exchangeRateAdjustments;
          this.balance =
            parseFloat(this.balanceMxn) / parseFloat(this.actualCurrency) +
            parseFloat(this.balanceUsd);
          this.balance = this.balance.toString();

          // this.totalUsd = 0;

          // this.itemsSelected.forEach((item) => {
          //   item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          //   this.totalUsd += item.dTotalUsdToday;
          // });

          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.getContacts();
        });
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalAddFounds() {

    },
    openModalSendPay() {
      this.dialogSendPay = true;
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;

      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dDesAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
            }
          } else {
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };


      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN"
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    },
    dataXLSX() {
      try {
        let data = this.conversions.map((item) => {
          return {
            Id: item.iTrackingId ? item.iTrackingId : "",
            "Transaction Date": item.created_at ? this.formatDate(item.created_at) : "",
            "Type Transaction": "Forward",
            "Due date": item.dForwardTransactionDate ? this.formatDate(item.dForwardTransactionDate) : "",
            "Missing days": item.diasFaltantes ? item.diasFaltantes : "0",
            "Total amount (USD)": item.dAmount ? parseFloat(item.dAmount) : "",
            "Total amount (MXN)": item.dDesAmount ? parseFloat(item.dDesAmount) : "",
            "Exchange rate": item.dExchangeRate ? item.dExchangeRate : "",
            Invoice: item.invoice ? item.invoice : "",
            "Purchase Date": item.purchaseDate ? item.purchaseDate : "",
            "Currency profit (%)": item.percentProfit ? parseFloat(item.percentProfit) : "",
            "Currency gain (USD)": item.dollarProfit ? parseFloat(item.dollarProfit) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getForwards();
    this.today = moment().format("YYYY-MM-DD");
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: fit-content;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #163969;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
