import Vue from "vue";
import Vuex from "vuex";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // for establishing initial tab when entering vendor detail from cart
    bComesFromCart: false,
    sToken: "",
    state: "",
    sFullName: "",
    sUserId: "",
    sEnterpriseId: "",
    language: localStorage.getItem("language") || "en",
    fadeMenu: false,
    fadeNotificationCart: false,
    isManager: false,
    isLogged: false,
    refresh: false,
    ocheckout: {},
    tabWarehouse: 0,
    iCurrentPage: 1,
    sSearch: "",
    startDate: null,
    endDate: null,
    paymentStatus: null,
    orderStatus: [],
    sFilter: "",
    sCategoryIdFilter: "",
    sSubcategoryIdFilter: "",
    profileDialogChangePassword: false,
    dialogProfileAddDocument: false,
    profileDialogAddUser: false,
    profileDialogEditUser: false,
    profileArrEditUser: [],

    // --- dialog create dispute --- //
    dialogCreateDisputeActive: false,
    dialogCreateDisputeArr: [],

    // --- dialogs items delete --- //
    dialogDeleteActive: false,
    dialogDeleteTitle: "",
    dialogDeleteApi: "",
    dialogDeleteRedirect: "",

    // --- dialog profile edit --- //
    dialogEditProfileActive: false,
    dialogEditProfileArr: [],

    // --- dialog gallery --- //
    galleryActive: false,
    galleryImageSelected: 0,
    gallerAllImages: [],

    //dialog rate order --- //
    rateOrderActive: false,
    ratePurchaseOrderId: "",
    rateSalesOrderId: "",
    rateVendorName: "",
  },
  mutations: {
    // --- reset comming from cart --- //
    setCommingFromCart(state, value) {
      state.bComesFromCart = value;
    },
    // --- is admin --- //
    setManager(state, value) {
      state.isManager = value;
    },

    // --- save token --- //
    setToken(state, value) {
      state.sToken = value;
    },

    // --- save fullname --- //
    setFullName(state, value) {
      state.sFullName = value;
    },

    // --- changing language --- //
    toggle_language(state, value) {
      state.language = value;
      localStorage.setItem("language", value);
    },

    // --- open and close menu --- //
    setFadeMenu(state, value) {
      state.fadeMenu = value;
    },

    // --- open notification shopping cart --- //
    setFadeNotificationCart(state, value) {
      state.fadeNotificationCart = value;
    },

    // --- validate session --- //
    setIsLogged(state, value) {
      state.isLogged = value;
    },

    // --- upadte information --- //
    refresher(state, item) {
      state.refresh = item;
    },
    updateCheckout(state, items) {
      state.ocheckout = items;
    },
    // --- search by text --- //
    setSearch(state, value) {
      state.sSearch = value;
    },

    setStartDate(state, value) {
      state.startDate = value;
    },

    setEndDate(state, value) {
      state.endDate = value;
    },

    setPaymentStatus(state, value) {
      state.paymentStatus = value;
    },

    setOrderStatus(state, value) {
      state.orderStatus = value;
    },

    // --- search by filter --- //
    setFilter(state, value) {
      state.sFilter = value;
    },

    // --- search by category --- //
    setCategoryIdFilter(state, value) {
      state.sCategoryIdFilter = value;
    },

    // --- search by subcategory --- //
    setSubcategoryIdFilter(state, value) {
      state.sSubcategoryIdFilter = value;
    },

    // --- current page --- //
    setICurrentPage(state, item) {
      state.iCurrentPage = item;
    },

    // --- get enterprise id --- //
    setEnterpriseId(state, value) {
      state.sEnterpriseId = value;
    },

    // --- get user id --- //
    setUserId(state, value) {
      state.sUserId = value;
    },

    setTabWarehouse(state, value) {
      state.tabWarehouse = value;
    },

    setDialogDelete(state, value) {
      state.dialogDeleteActive = value.active;
      state.dialogDeleteTitle = value.title;
      state.dialogDeleteApi = value.api;
      state.dialogDeleteRedirect = value.redirect;
    },

    setProfileDialogChangePassword(state, value) {
      state.profileDialogChangePassword = value;
    },

    setDialogProfileAddDocument(state, value) {
      state.dialogProfileAddDocument = value;
    },

    setProfileDialogAddUser(state, value) {
      state.profileDialogAddUser = value;
    },

    setProfileDialogEditUser(state, value) {
      state.profileDialogEditUser = value.active;
      state.profileArrEditUser = value.arr;
    },

    setDialogCreateDispute(state, value) {
      state.dialogCreateDisputeActive = value.active;
      state.dialogCreateDisputeArr = value.arr;
    },

    setDialogEditProfile(state, value) {
      state.dialogEditProfileActive = value.active;
      state.dialogEditProfileArr = value.arr;
    },

    setGallery(state, value) {
      state.galleryActive = value.active;
      state.galleryImageSelected = value.imageSelected;
      state.gallerAllImages = value.imagesArr;
    },

    setGalleryImageSelected(state, value) {
      state.galleryImageSelected = value;
    },

    setRateOrder(state, value) {
      state.rateOrderActive = value.active;
      state.ratePurchaseOrderId = value.purchaseOrderId;
      state.rateSalesOrderId = value.salesOrderId;
      state.rateVendorName = value.vendorName;
    },
  },
  actions: {
    setVerifiedEnterprise({ state }) {
      return new Promise((resolve, reject) => {
        DB.get(
          `${URI}/api/v1/${state.language}/customers/enterprises/${state.sEnterpriseId}`,
          {
            headers: {
              Authorization: "Bearer " + state.sToken,
            },
          }
        )
          .then((response) => {
            resolve(response.data.results.bVerified);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    setFillCheckoutGlobal({ state }) {
      return new Promise((resolve, reject) => {
        const params = {};
        const config = {
          headers: {
            Authorization: `Bearer ${state.sToken}`,
          },
        };

        DB.put(
          `${URI}/api/v1/${state.language}/customers/${state.sUserId}/shopping-cart`, params, config
        )
          .then((response) => {
            let aProviders = response.data.results.map((e) => {
              return {
                ...e,
                aProducts: e.aProducts.map((eProduct) => {
                  return {
                    ...eProduct,
                    dQuantityOrigin: eProduct.dQuantity,
                  };
                }),
                dTotalPallets: e.aProducts
                  .map((e) => {
                    return e.dPallets;
                  })
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  ),
              };
            });
            resolve(aProviders);
            // this.products = response.data.results.map((e) => {
            //   return {
            //     ...e,
            //     originQuantity: e.aProducts[0].dQuantity,
            //     aProducts: e.aProducts.map((eProduct) => {
            //       return {
            //         ...eProduct,
            //         dQuantityOrigin: eProduct.dQuantity,
            //       };
            //     }),
            //     dTotalPallets: e.aProducts
            //       .map((e) => {
            //         return e.dPallets;
            //       })
            //       .reduce(
            //         (accumulator, currentValue) => accumulator + currentValue
            //       ),
            //   };
            // });
            // this.$store.commit("refresher", false);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getValidateShoppingGlobal({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        dispatch("setFillCheckoutGlobal")
          .then((response) => {
            let aProviderTemp = response.filter(
              (e) => e.sProviderId === state.ocheckout.sProviderId
            );
            if (aProviderTemp.length > 0) {
              if (
                state.ocheckout.aProducts.length ===
                aProviderTemp[0].aProducts.length
              ) {
                for (let i = 0; i < state.ocheckout.aProducts.length; i++) {
                  const element = state.ocheckout.aProducts[i];
                  if (
                    aProviderTemp[0].aProducts.filter(
                      (e) => e.sProductId === element.sProductId
                    ).length === 0
                  ) {
                    resolve(false);
                    break;
                  } else {
                    resolve(true);
                  }
                }
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getVerifyShoppingCart({ state }, sVendorId) {
      return new Promise((resolve, reject) => {
     
        const config = {
          headers: {
            Authorization: `Bearer ${state.sToken}`,
          },
        },
          payload = {
            sVendorId: sVendorId,
          
        };

        DB.patch(
          `${URI}/api/v1/${state.language}/customers/${state.sUserId}/shopping-cart`,
          payload,
          config
        )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
  plugins: [PersistedState()],
});
