<template>
  <div>
    <div
      class="container-login"
      :style="{height: screenHeight + 'px'}"
    >
      <div class="content-left">
        <login-background-image-layout></login-background-image-layout>
      </div>
      <div class="content-right">
        <login-form-layout :texts="texts"></login-form-layout>
        <select-language-component />
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      //VARIABLES
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.login[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.login[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.container-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
}

.content-left {
  width: 55%;
  height: 100%;
  position: relative;
}

.content-right {
  width: 45%;
  height: 100%;
}

@media (min-width: 601px) and (max-width: 960px) {
  .content-left {
    width: 52%;
  }

  .content-right {
    width: 48%;
  }
}

@media (max-width: 720px) {
  .content-left {
    width: 100%;
    padding: 10px;
  }
  .content-right {
    display: none;
  }
}
</style>